.root {
  position: fixed;
  right: -25.5rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  width: 0;
  background: rgba(22, 25, 29, 0.7);
  border-radius: 1.25rem;
  transition: right 100ms ease-in, opacity 100ms ease-in, width 1000ms;
  opacity: 0;
}

.root .paper {
  height: 100%;
  width: 100%;
  background: #ffffff;
  border-radius: 0px;
  border-radius: 1.25rem;
}

.root .paper .header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 3.5rem;
  position: relative;
}

.root .paper .header .icon {
  margin-left: 0.5rem;
}



.root .paper .header .closeIconContainer {
  position: absolute;
  right: 1rem;
  color: #667085;
  cursor: pointer;
}

.open {
  right: 2rem;
  padding: 1.5rem;
  width: 25.5rem;
  opacity: 1;
  transition: right 200ms ease-out, opacity 200ms ease-out;
}
